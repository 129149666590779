import { Translation } from "../type"
const EN_TRANSACTIONS: Translation = {
  str_select: "Select",
  str_seo_title: "DIVER Storage",
  str_seo_description: "DIVER Storage",
  str_english: "English",
  str_japanese: "日本語",
  str_add: "Add",
  str_total_usage: "Total Usage",
  str_get_more_storage: "Get more storage",
  str_connect_wallet: "Connect Wallet",
  str_disconnect: "Disconnect",
  str_name: "Name",
  str_file_size: "File Size",
  str_all_rights_reserved: "All rights reserved",
  str_filter: "Filter",
  str_connect_diver_wallet: "Connect to your DIVER Wallet",
  str_install_diver_wallet_extension: "Install DIVER Wallet extension",
  str_failed_login: "Login Failed",
  str_please_switch_diver: "Please switch to DIVER Chain",
  str_switch_network: "Switch Network",
  str_last_modified: "Last Modified",
  str_type: "Type",
  str_file_deleted_successfully: "File deleted successfully",
  str_file_uploaded_successfully: "File uploaded successfully",
  str_file_moved_successfully: "File moved successfully",
  str_file_renamed_successfully: "File renamed successfully",
  str_download: "Download",
  str_delete: "Delete",
  str_details: "Details",
  str_rename: "Rename",
  str_file_move: "File move",
  str_move_option: "Move Options",
  str_upload_option: "Upload Options",
  str_upload_option_description:
    "One or more items already exists in this location. Do you want to replace the existing items with a new version or keep both items? Replacing the items won't change sharing settings.",
  str_replace_existing_items: "Replace existing items",
  str_keep_all_items: " Keep all items",
  str_upload: "Upload",
  str_cancel: "Cancel",
  str_apply: "Apply",
  str_move: "Move",
  str_cancel_move: "Cancel Move",
  str_overwrite: "Overwrite",
  str_not_enough_space: "Not enough space",
  str_upload_failed: "Upload failed",
  str_confirm_deletion_popup_title: "Do you want to delete the file?",
  str_confirm_deletion_popup_description: "Are you sure you want to delete {{filename}} from DIVER Storage?",
  str_upload_error_too_large: "The maximum uploaded size is {{maxFileSizeStr}}",
  str_add_new_folder: "Add new folder",
  str_upload_file: "Upload file",
  str_upload_folder: "Upload folder",
  str_moving: "moving",
  str_owner: "Owner",
  str_creation_date: "Creation Date",
  str_last_updated_date: "Last Updated Date",
  already_selected: "{{count}} selected",
  // Price Page
  str_price: "Price",
  str_price_page_title: "Choose the plan that's right for you.",
  str_life_time: "Life Time",
  str_get_offer: "Get Offer",
  str_confirm: "Confirm",
  str_confirm_purchase_popup_title__before: "Confirm Purchase",
  str_confirm_purchase_popup_title__purchasing: "Verifying Transaction...",
  str_confirm_purchase_popup_description:
    "Do you confirm the purchase of an additional {{space_str}} of storage?",
  str_purchase_successful: "Purchase successful!",
  str_insufficient_balance: "Insufficient balance",
  str_100_encrypted_communication: "100% encrypted communication",
  str_unlimited_devices: "Unlimited devices",
  str_space_storage: "{{space}} Storage",
  str_plan_basic: "Basic",
  str_plan_starter: "Starter",
  str_plan_standard: "Standard",
  str_plan_pro: "Pro",
  str_plan_business: "Business",
  str_most_popular: "MOST POPULAR",
  str_return_to_storage: "Return to Storage",
  str_confirm_purchase_popup_title__verifying: "Verifying Transaction...",
  str_redeem_with_nft: "Exchange with NFT",
  str_redeem_selecting_popup_title: "Select Voucher",
  str_redeem_selecting_popup_description: "Please choose the voucher you want to redeem",
  str_redeem_selecting_popup_confirm_btn: "Confirm",
  str_redeem_selecting_popup_cancel_btn: "Cancel",
  str_redeem_confirm_popup_success_message: "Redeem successful!",
  str_redeem_confirm_popup_title: "Confirm Redeem",
  str_redeem_confirm_popup_description: "Are you sure to redeem additional {{space_str}} of storage?",
  str_transaction_not_successful: "Transaction not successful",
  // Search Bar
  str_search_in_this_folder: "Search in this folder",
  // Upload Hint
  str_uploading_x_items: "uploading {{count}} items",
  str_not_upload_folder: "You can only upload files.",
  str_storage: "Storage",
  str_history: "History",
  str_purchase_date: "Purchase date",
  str_receipt: "Receipt",
  str_pay_by: "Pay",
  str_uploading_n_files: "Uploading {{count}} items",
  str_uploaded_n_files: "Uploaded {{count}} items",
  str_drag_and_drop_file: "Drag and drop the file here to upload it",
  minute_left: "{{count}} {{unit}} left",
  // Download Hint
  preparing_for_download: "Preparing for download",
  start_downloading: "Start downloading",
  compressing_x_archives: "compressing {{count}} archives",
  compression_failed: "Compression failed",
  file_compressed: "{{count}} files compressed",
  // Login Popup
  wallet_connecting: "Connecting Wallet",
  wallet_connecting_text: "I have a DIVER Wallet",
  wallet_connecting_download_text: "Click here if you are a first-time visitor",
  wallet_connecting_download_web: "Add the DIVER Wallet extension",
  wallet_connecting_download_rwd: "Download DIVER Wallet",
  // Popup
  file_more_than_4GB: "The file you are uploading contains a file larger than 4GB, which may take a longer time to upload. Are you sure you want to continue the upload?",
  cannot_delete: "File upload in progress. Editing or deletion is not available at the moment.\nPlease refresh the page after the upload is completed and try again.",
  duplicate_file_name: "Duplicate file name",
  move_n_items: "Move {{count}} items",
  move_duplicate_name_file: "There are files with the same name in the storage space. Would you like to overwrite the original file or cancel the file move?",
  current_position: "Current position",
  only_100_files_can_be_uploaded: "The number of files being uploaded is limited to 100. Please try again later.",
  // Add New Folder
  folder_name: "Folder Name",
  name_cannot_empty: "Please enter the name.",
  name_error: "The file name cannot contain any of the following characters::\\/:*?”<>|{^}%`[]~#",
  name_too_long: "The name must not exceed 255 characters.",
  name_duplicate: "Duplicate name",
  installation_cannot_drag: "Installation files cannot be uploaded via drag-and-drop.",
  // Error msg
  folder_name_can_not_contain_special_characters: "Folder with special characters in the name are not allowed to be uploaded.",
  file_name_can_not_contain_special_characters: "Files with special characters in the name are not allowed to be uploaded.",
  folder_contain_too_large_file: "The folder contains files exceeding {{maxFileSizeStr}} in size.",
  // Filter
  today: "today",
  past_7_days: "Past 7 Days",
  past_30_days: "Past 30 Days",
  custom_date: "Custom date range",
}
export default EN_TRANSACTIONS
