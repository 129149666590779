import { Translation } from "../type"
const JA_TRANSACTIONS: Translation = {
  str_select: "選択された",
  str_seo_title: "DIVER Storage",
  str_seo_description: "DIVER Storage",
  str_english: "English",
  str_japanese: "日本語",
  str_add: "新規",
  str_total_usage: "保存容量",
  str_get_more_storage: "保存容量を増やす",
  str_connect_wallet: "ウォレットを接続",
  str_disconnect: "ウォレットを切断",
  str_name: "名前",
  str_file_size: "ファイルサイズ",
  str_all_rights_reserved: "全ての権利を有する",
  str_filter: "フィルター",
  str_connect_diver_wallet: "DIVERウォレットに接続する",
  str_install_diver_wallet_extension:
    "DIVERウォレット拡張機能をインストールする",
  str_failed_login: "ログインに失敗しました",
  str_please_switch_diver: "DIVER Chainに切り替えてください",
  str_switch_network: "ネットワークを切り替える",
  str_last_modified: "最終更新日",
  str_type: "タイプ",
  str_file_deleted_successfully: "ファイルを正常に削除しました",
  str_file_uploaded_successfully: "ファイルを正常にアップロードしました",
  str_file_moved_successfully: "ファイルは正常に移動されました",
  str_file_renamed_successfully: "ファイル名の変更に成功しました",
  str_download: "ダウンロード",
  str_delete: "削除",
  str_details: "詳細",
  str_rename: "名前を変更",
  str_file_move: "ファイルを移動",
  str_move_option: "移動オプション",
  str_upload_option: "アップロードオプション",
  str_upload_option_description:
    "この場所にはすでにアイテムが存在します。既存のアイテムを新しい版に置換しますか？それとも両方のアイテムを保持しますか？アイテムを置換しても、共有設定は変更されません。",
  str_replace_existing_items: "既存のアイテムを置換する",
  str_keep_all_items: "すべてのアイテムを保持する",
  str_upload: "アップロード",
  str_cancel: "キャンセル",
  str_apply: "適用する",
  str_move: "移動",
  str_cancel_move: "移動をキャンセル",
  str_overwrite: "上書き",
  str_not_enough_space: "保存容量が不足しています",
  str_upload_failed: "アップロード失敗",
  str_confirm_deletion_popup_title: "ファイルを削除しますか？",
  str_confirm_deletion_popup_description:
    "DIVER Storageから{{filename}}を削除してもよろしいですか？",
  str_upload_error_too_large: "アップロード最大ファイルサイズ {{maxFileSizeStr}}",
  str_add_new_folder: "新しいフォルダを追加",
  str_upload_file: "ファイルのアップロード",
  str_upload_folder: "フォルダーのアップロード",
  str_moving: "移動中",
  str_owner: "所有者",
  str_creation_date: "作成日",
  str_last_updated_date: "最終更新",
  already_selected: "{{count}}個選択中",
  // Price Page
  str_price: "価格",
  str_price_page_title: "あなたに合ったプランを選択してください",
  str_life_time: "ライフタイム",
  str_get_offer: "購入する",
  str_confirm: "確認",
  str_confirm_purchase_popup_title__before: "購入を確認",
  str_confirm_purchase_popup_title__purchasing: "取引を確認中...",
  str_confirm_purchase_popup_description:
    "{{space_str}} の追加ストレージの購入を確認しますか？",
  str_purchase_successful: "購入成功！",
  str_insufficient_balance: "残高不足",
  str_100_encrypted_communication: "100% 暗号化通信",
  str_unlimited_devices: "無制限のデバイス",
  str_space_storage: "{{space}} ストレージ",
  str_plan_basic: "ベーシック",
  str_plan_starter: "スターター",
  str_plan_standard: "スタンダード",
  str_plan_pro: "プロ",
  str_plan_business: "ビジネス",
  str_most_popular: "最も人気",
  str_return_to_storage: "Storage に戻る",
  str_redeem_with_nft: "引き換える",
  str_redeem_selecting_popup_title: "引換券を選択",
  str_redeem_selecting_popup_description:
    "所有中の引換券から選択してください。",
  str_confirm_purchase_popup_title__verifying: "トランザクションを確認中...",
  str_redeem_selecting_popup_confirm_btn: "確認",
  str_redeem_selecting_popup_cancel_btn: "キャンセル",
  str_redeem_confirm_popup_success_message: "引き換え成功！",
  str_redeem_confirm_popup_title: "引き換え確認",
  str_redeem_confirm_popup_description:
    "追加で {{space_str}} のストレージを引き換えますか？",
  str_transaction_not_successful: "取引が成功していません",
  // Search Bar
  str_search_in_this_folder: "このフォルダ内を検索",
  // Upload Hint
  str_uploading_x_items: "{{count}} アイテムをアップロード中",
  str_not_upload_folder: "ファイルしかアップロードできません",
  minute_left: "あと {{count}} {{unit}}",
  // Download Hint
  preparing_for_download: "ダウンロードの準備中",
  start_downloading: "ダウンロードの準備中",
  compressing_x_archives: "{{count}} 個のアーカイブを圧縮しています",
  compression_failed: "圧縮に失敗しました",
  file_compressed: "{{count}} 個のファイルが圧縮されました",
  // History Page
  str_storage: "ストレージ",
  str_history: "歴史",
  str_pay_by: "支払う",
  str_purchase_date: "購入日",
  str_receipt: "領収書",
  str_uploading_n_files: "{{count}} アイテムをアップロード中",
  str_uploaded_n_files: "{{count}} アイテムをアップロード完了",
  str_drag_and_drop_file: "こちらにファイルをドロップするとアップロードできます",
  // Login Popup
  wallet_connecting: "ウォレットを接続する",
  wallet_connecting_text: "DIVER Walletをお持ちの方",
  wallet_connecting_download_text: "初めての方",
  wallet_connecting_download_web: "DIVER Walletの拡張機能を追加する",
  wallet_connecting_download_rwd: "ウォレットをダウンロード",
  // Popup
  file_more_than_4GB: "アップロードしているファイルには4GBを超えるファイルが含まれています。アップロードには時間がかかる場合があります。アップロードを続行しますか?",
  cannot_delete: "アップロード中は編集や削除はできません、アップロードが完了して画面をリロードしてからもう一度操作してください",
  duplicate_file_name: "重複ファイル名",
  move_n_items: "{{count}} 個のアイテムを移動",
  move_duplicate_name_file: "ストレージ内に同じ名前のファイルがあります。元のファイルを上書きしますか、それともファイルの移動をキャンセルしますか？",
  current_position: "現在の場所",
  only_100_files_can_be_uploaded: "アップロード中のファイル数は最大で100個までです。後でもう一度お試しください",
  // Add New Folder
  folder_name: "フォルダー名",
  name_cannot_empty: "名前を入力してください",
  name_error: "ファイル名には、次の文字を含めることはできません:\\/:*?”<>|{^}%`[]~#",
  name_too_long: "ファイル名は255文字以内で入力してください",
  name_duplicate: "重複した名前",
  installation_cannot_drag: "インストールファイルはドロップでのアップロードはできません",
  // Error msg
  folder_name_can_not_contain_special_characters: "名前に特殊文字を含むフォルダはアップロードできません",
  file_name_can_not_contain_special_characters: "名前に特殊文字が含まれるファイルのアップロードは許可されていません",
  folder_contain_too_large_file: "フォルダーには容量が {{maxFileSizeStr}} を超えるファイルが含まれています",
  // Filter
  today: "今日",
  past_7_days: "過去 7 日間",
  past_30_days: "過去 30 日間",
  custom_date: "カスタムの日付範囲",
}

export default JA_TRANSACTIONS
