import React from "react"
import { useTranslation } from "react-i18next"
import { DialogTypeEnum } from "@/core/constant/DialogTypeEnum"
import { CommonButton } from "./CommonButton"
import { CommonDialogProps } from "./CommonDialog"
import WarningRoundedIcon from "@mui/icons-material/WarningRounded"

export const AlertComponent: React.FunctionComponent<CommonDialogProps> = ({
  title,
  close = () => undefined,
  type,
  content,
  buttonLeft,
  buttonRight,
  icon,
}) => {
  const { t } = useTranslation()
  const IconRenderer = ({ iconName }: { iconName: string }) => {
    switch (iconName) {
      case "warning":
        return <WarningRoundedIcon color="warning" fontSize="large" />
      default:
        return null
    }
  }

  const btnLeftClick = () => {
    if (buttonLeft?.action) {
      buttonLeft.action()
    }
    close()
  }

  const btnRightClick = () => {
    if (buttonRight?.action) {
      buttonRight.action()
    }
    close()
  }

  return (
    <div className="w-[420px] p-5 relative">
      <div className="text-lg font-bold mb-5 text-center">
        {title}
        {icon && <IconRenderer iconName={icon} />}
      </div>
      <div className="text-body2 px-10">{content}</div>
      <div
        className={`flex px-4 mt-7 ${
          type === DialogTypeEnum.INFO ? "justify-center" : "justify-between"
        }`}
      >
        {buttonLeft && (
          <CommonButton
            className="w-1/2"
            variant="outlined"
            onClick={btnLeftClick}
            sx={{
              marginRight: 2,
              minWidth: "auto",
              padding: "10px 20px",
            }}
          >
            {buttonLeft.text || t("str_cancel")}
          </CommonButton>
        )}
        {buttonRight && (
          <CommonButton
            className="w-1/2"
            color={type === DialogTypeEnum.DELETE ? "error" : "primary"}
            variant="contained"
            onClick={btnRightClick}
            sx={{
              marginLeft: 2,
              minWidth: "auto",
              padding: "10px 20px",
            }}
          >
            {buttonRight.text || t("str_confirm")}
          </CommonButton>
        )}
      </div>
    </div>
  )
}
